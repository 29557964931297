import React from "react"
import classNames from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons"

import MedicinesCardRow from "./MedicinesCard/MedicinesCardRow"
import styles from "./utils/search.module.scss"

const MedicinesCard = ({
  searchResults,
  isExactMatch,
  onSelect,
  setSearchResults,
  module,
  setSearchQuery,
}) => {
  const otherMedicineMessage =
    module === "request" ? `, or choose "Other Medicine"` : ""
  return (
    <section className={classNames(styles["searchResult"])}>
      <ol className={classNames({ "is-hidden": !searchResults.length })}>
        {isExactMatch && (
          <li className={classNames(styles["errorMessage"])}>
            <FontAwesomeIcon icon={faExclamationCircle} />{" "}
            <b>Item cannot be found.</b> Please type again, choose the closest
            possible match{otherMedicineMessage}.
          </li>
        )}
        {searchResults.slice(0, 10).map((result) => (
          <MedicinesCardRow
            medicine={result}
            handleClick={() => {
              if (onSelect) onSelect(result)
              setSearchResults([])
              setSearchQuery("")
            }}
          />
        ))}
      </ol>
    </section>
  )
}
export default MedicinesCard
