export const daysOfTheWeek = {
  "16/5": [
    {
      label: "Any day",
      value: "Any day",
    },
    {
      label: "Mon",
      value: "Mon",
    },
    {
      label: "Tue",
      value: "Tue",
    },
    {
      label: "Wed",
      value: "Wed",
    },
    {
      label: "Thu",
      value: "Thu",
    },
    {
      label: "Fri",
      value: "Fri",
    },
  ],
  "24/7": [
    {
      label: "Any day",
      value: "Any day",
    },
    {
      label: "Mon",
      value: "Mon",
    },
    {
      label: "Tue",
      value: "Tue",
    },
    {
      label: "Wed",
      value: "Wed",
    },
    {
      label: "Thu",
      value: "Thu",
    },
    {
      label: "Fri",
      value: "Fri",
    },
    {
      label: "Sat",
      value: "Sat",
    },
    {
      label: "Sun",
      value: "Sun",
    },
  ],
}

export const timeBlocks = {
  "16/5": [
    {
      label: "Any time",
      value: "Any time",
    },
    {
      label: "8AM-11AM",
      value: "8AM-11AM",
    },
    {
      label: "11AM-2PM",
      value: "11AM-2PM",
    },
    {
      label: "2PM-5PM",
      value: "2PM-5PM",
    },
    {
      label: "5PM-8PM",
      value: "5PM-8PM",
    },
  ],
  "24/7": [
    {
      label: "Any time",
      value: "Any time",
    },
    {
      label: "12MN-3AM",
      value: "12MN-3AM",
    },
    {
      label: "3AM-6AM",
      value: "3AM-6AM",
    },
    {
      label: "6AM-9AM",
      value: "6AM-9AM",
    },
    {
      label: "9AM-12NN",
      value: "9AM-12NN",
    },
    {
      label: "12NN-3PM",
      value: "12NN-3PM",
    },
    {
      label: "3PM-6PM",
      value: "3PM-6PM",
    },
    {
      label: "6PM-9PM",
      value: "6PM-9PM",
    },
    {
      label: "9PM-12MN",
      value: "9PM-12MN",
    },
  ],
}
