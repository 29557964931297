// import React, { Fragment, useRef, useEffect, useState } from "react"
// import { Field, useFormikContext } from "formik"
// import classNames from "classnames"
// import styles from "./utils/form.module.scss"
// import { getFollowUpQuestionData, generateFormField } from "./services/form"
// import { camelize } from "humps"

// import handleScrollToError from "./utils/handleScrollToError"

// const FormStackedRadio = ({
//   name,
//   options,
//   value,
//   onChange,
//   title,
//   disabled,
//   isRequired,
//   hideOptional,
//   helper,
//   helperClassName,
//   followUpQuestions = [],
//   formFields,
//   formValues,
//   setFieldValue,
//   isFollowUpQuestion,
//   dataSource,
// }) => {
//   const formik = useFormikContext()
//   const fieldRef = useRef(null)

//   const [ isActive, setIsActive ] = useState(false)

//   const handleScrollCallback = () => {
//     fieldRef.current.scrollIntoView({ scroll: "smooth", block: "center" })
//   }

//   useEffect(() => {
//     handleScrollToError({
//       formikContext: formik,
//       fieldName: name,
//       callback: handleScrollCallback,
//     })
//   }, [formik.submitCount, formik.isValid, formik, name])

//   useEffect(() => {
//     if (isFollowUpQuestion) handleScrollCallback()
//   }, [isFollowUpQuestion])

//   const handleChange = (form, option) => (event) => {
//     const { setFieldValue } = form
//     if (event.target.checked) setFieldValue(name, event.target.value)
//     if (onChange) onChange(event)
//     console.log(setFieldValue(name, event.target.value))
//   }

//   const RadioButton = ({ form, option, index }) => (
//     <div className={classNames(styles["formMhaRadio__option"], styles[isActive ? "active" : null])}>
//       <input
//         id={`option${index + 1}RadioButton${name}`}
//         type="radio"
//         name={name}
//         value={option}
//         onChange={handleChange(form, option)}
//         checked={option === value}
//         disabled={disabled}
//       />
//       <label for={`option${index + 1}RadioButton${name}`}>{option}</label>
//     </div>
//   )

//   return (
//     <div className={classNames(styles["formMhaRadio"])}>
//       <Field name={name}>
//         {({ form }) => (
//           <Fragment>
//             {title && (
//               <div
//                 className={classNames(
//                   styles["formMhaRadio__questionContainer"]
//                 )}
//               >
//                 <label ref={fieldRef}>
//                   {title}{" "}
//                   {!isRequired && !hideOptional && (
//                     <span className="has-text-grey is-italic"> (Optional)</span>
//                   )}
//                   {!!helper && (
//                     <span
//                       className={classNames(
//                         "help has-text-weight-normal",
//                         helperClassName
//                       )}
//                     >
//                       {helper}
//                     </span>
//                   )}
//                 </label>
//               </div>
//             )}
//             <div>
//               {options.map((option, index) => (
//                 <Fragment key={index}>
//                   <RadioButton form={form} option={option} index={index} />
//                   {followUpQuestions?.length > 0 &&
//                     followUpQuestions.map((followUpQuestion) => {
//                       const getFormField = getFollowUpQuestionData({
//                         followUpQuestion,
//                         formFields: formFields,
//                       })

//                       if (
//                         value === option &&
//                         camelize(getFormField?.referenceAnswer) ===
//                           camelize(option)
//                       )
//                         return (
//                           <div className="notification is-light ml-2 mt-1">
//                             {generateFormField({
//                               formField: {
//                                 ...getFormField,
//                                 disabled: disabled,
//                               },
//                               formFields,
//                               values: formValues,
//                               setFieldValue,
//                               dataSource,
//                             })}
//                           </div>
//                         )
//                       return null
//                     })}
//                 </Fragment>
//               ))}
//             </div>
//           </Fragment>
//         )}
//       </Field>
//     </div>
//   )
// }

// export default FormStackedRadio


import React, { Fragment, useRef, useEffect, useState } from "react";
import { Field, useFormikContext } from "formik";
import classNames from "classnames";
import styles from "./utils/form.module.scss";
import { getFollowUpQuestionData, generateFormField } from "./services/form";
import { camelize } from "humps";
import handleScrollToError from "./utils/handleScrollToError";

const FormStackedRadio = ({
  name,
  options,
  value,
  onChange,
  title,
  disabled,
  isRequired,
  hideOptional,
  helper,
  helperClassName,
  followUpQuestions = [],
  formFields,
  formValues,
  setFieldValue,
  isFollowUpQuestion,
  dataSource,
}) => {
  const formik = useFormikContext();
  const fieldRef = useRef(null);
  const [isActive, setIsActive] = useState(false);

  const handleScrollCallback = () => {
    fieldRef.current.scrollIntoView({ scroll: "smooth", block: "center" });
  };

  useEffect(() => {
    handleScrollToError({
      formikContext: formik,
      fieldName: name,
      callback: handleScrollCallback,
    });
  }, [formik.submitCount, formik.isValid, formik, name]);

  useEffect(() => {
    setIsActive(options.includes(value));
  }, [value, options]);

  useEffect(() => {
    if (isFollowUpQuestion) handleScrollCallback();
  }, [isFollowUpQuestion]);

  const handleChange = (form, option) => (event) => {
    const { setFieldValue } = form;
    setFieldValue(name, event.target.value);
    setIsActive(option === event.target.value);
    if (onChange) onChange(event);
  };

  const RadioButton = ({ form, option, index }) => (
    <div
      className={classNames(styles["formMhaRadio__option"], {
        [styles.active]: isActive && option === value,
      })}
    >
      <input
        id={`option${index + 1}RadioButton${name}`}
        type="radio"
        name={name}
        value={option}
        onChange={handleChange(form, option)}
        checked={option === value}
        disabled={disabled}
      />
      <label htmlFor={`option${index + 1}RadioButton${name}`}>{option}</label>
    </div>
  );

  return (
    <div className={classNames(styles["formMhaRadio"])}>
      <Field name={name}>
        {({ form }) => (
          <Fragment>
            {title && (
              <div
                className={classNames(
                  styles["formMhaRadio__questionContainer"]
                )}
              >
                <label ref={fieldRef}>
                  {title}{" "}
                  {!isRequired && !hideOptional && (
                    <span className="has-text-grey is-italic"> (Optional)</span>
                  )}
                  {!!helper && (
                    <span
                      className={classNames(
                        "help has-text-weight-normal",
                        helperClassName
                      )}
                    >
                      {helper}
                    </span>
                  )}
                </label>
              </div>
            )}
            <div>
              {options.map((option, index) => (
                <Fragment key={index}>
                  <RadioButton form={form} option={option} index={index} />
                  {followUpQuestions?.length > 0 &&
                    followUpQuestions.map((followUpQuestion) => {
                      const getFormField = getFollowUpQuestionData({
                        followUpQuestion,
                        formFields: formFields,
                      });

                      if (
                        value === option &&
                        camelize(getFormField?.referenceAnswer) ===
                          camelize(option)
                      )
                        return (
                          <div className="notification is-light ml-2 mt-1">
                            {generateFormField({
                              formField: {
                                ...getFormField,
                                disabled: disabled,
                              },
                              formFields,
                              values: formValues,
                              setFieldValue,
                              dataSource,
                            })}
                          </div>
                        );
                      return null;
                    })}
                </Fragment>
              ))}
            </div>
          </Fragment>
        )}
      </Field>
    </div>
  );
};

export default FormStackedRadio;
