import React, { Fragment } from "react"

import RxIndicator from "elements/RxIndicator"

const BrandTag = ({ values }) => {
  if (Array.isArray(values) && values.length > 0) {
    return (
      <Fragment>
        <span className="help">
          Sample Brands: {values.map((value) => value.Brand[0]).join(", ")}
        </span>
      </Fragment>
    )
  } else {
    return (
      <Fragment>
        <span className="help">Sample Brand: {values.Brand[0]}</span>
      </Fragment>
    )
  }
}

const MedicinesCardRow = ({ 
  medicine,
  index,
  showDeleteButton,
  handleClick,
  handleDeleteMedicine,
}) => (
  <li aria-hidden="true" onClick={() => handleClick()}>
    {showDeleteButton &&
      <button
        onClick={() => handleDeleteMedicine(index)}
        className="delete is-pulled-right ml-1"
        type="button"
        aria-label="Delete Button"
      ></button>
    }

    <div className="is-flex has-text-weight-bold mb-1">
      <div className="has-text-left">{medicine?.DrugName}</div>
      <RxIndicator />
    </div>
    {medicine?.values && <BrandTag values={medicine?.values} />}
  </li>
)

export default MedicinesCardRow
