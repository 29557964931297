import React, { useState, useEffect, Fragment } from "react"

import classNames from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSearch } from "@fortawesome/free-solid-svg-icons"
import { ErrorMessage } from "formik"

import { fuzzySearch, groupBy } from "./services/fuzzySearch"
import MedicinesCard from "./MedicinesCard"
import MedicinesCardRow from "./MedicinesCard/MedicinesCardRow"

import Message from "../Elements/Message"

import styles from "./utils/search.module.scss"

const Search = ({
  label,
  isRequired,
  type,
  name,
  placeholder,
  withResults,
  dataSource,
  onSelect,
  field = {},
  withIcon,
  handleChange,
  isMobile,
  module,
  helper,
  message,
  formValues,
  values,
  value = [],
  setFieldValue,
}) => {
  const [searchQuery, setSearchQuery] = useState("")
  const [searchQueue, setSearchQueue] = useState(null)
  const [searchResults, setSearchResults] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isOptionHidden, setOptionHidden] = useState(true)

  let fieldValue = values || value

  useEffect(() => {
    setSearchResults([])
    if (searchQuery) {
      setIsLoading(true)
      switch (dataSource.type) {
        case "api":
          break
        case "graphql":
          if (searchQueue) clearTimeout(searchQueue)
          setSearchQueue(
            setTimeout(async () => {
              let searchResults = await fuzzySearch(
                searchQuery,
                dataSource.data,
                dataSource.lookupKeys
              )
              searchResults = groupBy(searchResults, "DrugName")
              setSearchResults(searchResults)
              setIsLoading(false)
            }, 1000)
          )
          break
        default:
          break
      }
    }

    //eslint-disable-next-line
  }, [searchQuery])

  const handleValidateMatchDistance = () => {
    let resultValues = searchResults[0]?.values
    let distance = Array.isArray(resultValues)
      ? resultValues[0]?.distance
      : resultValues?.distance
    return searchResults.length > 0 && distance !== 1
  }

  const handleOnKeyDown = (event) => {
    if (event.key === "Enter" || event.key === "Tab") setOptionHidden(true)
  }

  const handleOnSelect = async (item) => {
    let addedMedicines = values.filter(
      (medicine) => medicine.DrugName === item.DrugName
    )

    let isMedicineAlreadyAdded = addedMedicines.length

    if (!isMedicineAlreadyAdded) {
      let newValue = [...fieldValue]
      newValue.push(item)
      setFieldValue(name, newValue)
    }
  }

  const handleDeleteMedicine = (index) => {
    values.splice(index, 1)
  }

  return (
    <Fragment>
      <div className="field">
        {!!label && (
          <label
            className={classNames("label has-text-weight-normal has-text-left")}
          >
            {label}
            {!!helper?.type && (
              <span
                className={classNames(
                  "help has-text-weight-normal",
                  helper?.className || ""
                )}
              >
                {helper?.message}
              </span>
            )}
          </label>
        )}
        <div className="field-body">
          <div className="field">
            <p
              className={classNames("control has-icons-left", {
                "is-loading": isLoading,
                " has-icons-left": withIcon,
              })}
            >
              <input
                type={type}
                className="input"
                name={name}
                placeholder={placeholder}
                {...field}
                onChange={(event) => {
                  if (field.onChange) field.onChange(event)
                  if (isOptionHidden) setOptionHidden(false)
                  if (handleChange) handleChange(event)
                  setSearchQuery(event.target.value)
                }}
                onKeyDown={handleOnKeyDown}
                readOnly={isMobile}
                value={searchQuery}
                // onBlur={handleOnBlur}
              />
              {withIcon && (
                <span className={classNames("icon is-small is-left")}>
                  <FontAwesomeIcon icon={faSearch} />
                </span>
              )}
            </p>
          </div>
        </div>
        <p className="help is-danger mt-0 mb-1">
          <ErrorMessage name={name} />
        </p>
      </div>

      {!isOptionHidden && (
        <MedicinesCard
          searchResults={searchResults}
          isExactMatch={handleValidateMatchDistance()}
          onSelect={handleOnSelect}
          setSearchResults={setSearchResults}
          setSearchQuery={setSearchQuery}
          module={module}
        />
      )}

      {!!message?.content ? (
        <Message color={message?.color} className="mt-1">
          {message?.content}
        </Message>
      ) : null}

      <ol className={classNames(styles["medicineCard"])}>
        {values?.map((medicine, index) => (
          <MedicinesCardRow
            medicine={medicine}
            index={index}
            showDeleteButton
            handleClick={() => {
              if (onSelect) onSelect(medicine)
              setSearchResults([])
              setSearchQuery("")
            }}
            handleDeleteMedicine={handleDeleteMedicine}
          />
        ))}
      </ol>
    </Fragment>
  )
}

export default Search
